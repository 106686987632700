.embedded-website-container {
  width: 80%;
  margin: 0 auto; /* Center the content */
}

/* Add additional responsive styling if needed */
.sk-ww-etsy-reviews {
  /* Add styles for the embedded content if necessary */
  width: 100%; /* Ensure the embedded content is responsive within its container */
}
.embedded-website-container {
  text-align: center;
}

.embedded-website-heading {
  color: #6fa82f;
  font-family: "DM Serif Display", serif;
  font-size: 34px; /* Default size for larger screens */
  line-height: 32px; 
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .embedded-website-heading {
      font-size: 24px; /* Adjust font size for smaller screens */
      margin-top: 30px; /* Adjust margin for smaller screens */
      margin-bottom: 30px; /* Adjust margin for smaller screens */
  }
}

